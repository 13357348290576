// extracted by mini-css-extract-plugin
export var publisherButton = "style-module--publisher-button--96e4c";
export var publisherComics = "style-module--publisher-comics--f2218";
export var publisherCover = "style-module--publisher-cover--e6525";
export var publisherCoverWrapper = "style-module--publisher-cover-wrapper--6eb4f";
export var publisherDetail = "style-module--publisher-detail--68ec4";
export var publisherInfo = "style-module--publisher-info--7e16c";
export var publisherLink = "style-module--publisher-link--a7edf";
export var publisherPage = "style-module--publisher-page--160eb";
export var publisherSubtitle = "style-module--publisher-subtitle--dc88e";
export var publisherTech = "style-module--publisher-tech--ec1dd";
export var publisherTechTitle = "style-module--publisher-tech-title--2daba";
export var publisherTitle = "style-module--publisher-title--82d71";
export var publisherTop = "style-module--publisher-top--54908";